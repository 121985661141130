// import React, { useState, useEffect, useRef } from 'react';
// import { 
//     MapContainer, 
//     TileLayer, 
//     Marker, 
//     Popup, 
//     useMap 
// } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import 'leaflet.heat';
// import axios from 'axios';
// import './CoverageMap.css';
// import './Loader.css';
// import { FaCog } from 'react-icons/fa';
// import Top from './Top'; // Importing the Top menu component

// // Constants for map setup
// const startingOfficeCoords = { lat: -20.264961457551927, lng: 57.59215085036571 };
// const startingOfficeLogo = '/markersm.png'; // Ensure the image is in the public folder

// // Define the custom icon for the office marker
// const officeMarkerIcon = new L.Icon({
//     iconUrl: startingOfficeLogo,
//     iconSize: [25, 38],
//     iconAnchor: [12, 38],
//     popupAnchor: [1, -34],
// });

// // Define the custom icon for tower markers
// const towerIconPath = '/markersm.png'; // Ensure the image is in the public folder
// const towerIcon = new L.Icon({
//     iconUrl: towerIconPath,
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//     popupAnchor: [1, -34],
// });

// // Styles for various components
// const styles = {
//     pageContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100vh',
//     },
//     topMenuSpacer: {
//         height: '5px', // Adjust to match the Top menu height
//     },
//     mapWrapper: {
//         flex: 1,
//         position: 'relative',
//     },
//     mapContainer: {
//         height: '100%',
//         width: '100%',
//     },
//     searchContainer: {
//         position: 'absolute',
//         top: '80px', // Adjusted to accommodate the Top menu
//         left: '50px', // Shifted right to make room for the gear icon
//         zIndex: 1000,
//         backgroundColor: 'white',
//         padding: '10px',
//         borderRadius: '5px',
//         boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
//         maxHeight: '90vh',
//         overflowY: 'auto',
//         width: '360px', // Increased width by 20% from 300px to 360px
//         transition: 'transform 0.8s ease-in-out',
//         transform: 'translateX(0)', // Default to visible
//     },
//     loaderContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         height: '100vh',
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         width: '100%',
//         backgroundColor: 'rgba(255, 255, 255, 0.7)',
//         zIndex: 2000,
//     },
//     loadingQuote: {
//         marginTop: '20px',
//         fontSize: '1em',
//         textAlign: 'center',
//         color: '#333',
//     },
//     popupContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         textAlign: 'center',
//     },
//     popupText: {
//         marginBottom: '10px',
//     },
//     searchButton: {
//         marginTop: '10px',
//         padding: '5px 10px',
//         backgroundColor: '#61acb4',
//         color: 'white',
//         border: 'none',
//         borderRadius: '3px',
//         cursor: 'pointer',
//     },
//     buildButton: {
//         marginTop: '10px',
//         padding: '10px 20px',
//         backgroundColor: '#61acb4',
//         color: 'white',
//         border: 'none',
//         borderRadius: '5px',
//         cursor: 'pointer',
//     },
//     errorMessage: {
//         marginTop: '10px',
//         color: 'red',
//         textAlign: 'center',
//     },
//     gearIcon: {
//         position: 'absolute',
//         top: '80px', // Positioned below the Top menu
//         left: '10px',
//         zIndex: 1100,
//         backgroundColor: '#fff',
//         padding: '8px',
//         borderRadius: '50%',
//         boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
//         cursor: 'pointer',
//     },
//     searchPanelClosed: {
//         transform: 'translateX(-100%)',
//     },
//     searchPanelOpen: {
//         transform: 'translateX(0)',
//     },
// };

// // Helper component to update the map center
// function UpdateMapCenter({ latLng }) {
//     const map = useMap();
//     useEffect(() => {
//         map.setView([latLng.lat, latLng.lng], 11);
//     }, [latLng, map]);
//     return null;
// }

// // Helper component to add custom attribution (e.g., logo)
// function CustomAttribution() {
//     const map = useMap();

//     useEffect(() => {
//         const logoControl = L.control({ position: 'bottomright' });
//         logoControl.onAdd = function () {
//             const div = L.DomUtil.create('div', 'custom-attribution');
//             div.innerHTML = `<img src="/smartmaplogotext.png" alt="Logo" style="height: 40px;"/>`; // Ensure the image is in the public folder
//             return div;
//         };
//         logoControl.addTo(map);
//         return () => {
//             map.removeControl(logoControl);
//         };
//     }, [map]);

//     return null;
// }

// // Helper component to render the heatmap layer
// const HeatmapLayer = ({ heatmapData }) => {
//     const map = useMap();

//     useEffect(() => {
//         if (!heatmapData || heatmapData.length === 0) return;

//         const heatLayer = L.heatLayer(heatmapData, {
//             radius: 25,
//             blur: 15,
//             maxZoom: 17,
//             maxOpacity: 0.3, // 70% opacity for 30% transparency
//             gradient: { 0.4: 'red', 0.45: 'lime', 1: 'blue' },
//         }).addTo(map);

//         return () => {
//             map.removeLayer(heatLayer);
//         };
//     }, [heatmapData, map]);

//     return null;
// };

// // Main CoverageMap component
// const CoverageMap = () => {
//     const [latLng, setLatLng] = useState(startingOfficeCoords);
//     const [towers, setTowers] = useState([]);
//     const [selectedBaseStations, setSelectedBaseStations] = useState({});
//     const [selectAll, setSelectAll] = useState(true);
//     const [coverageData, setCoverageData] = useState({}); // { towerId: coverageGeoJSON }
//     const [heatmapData, setHeatmapData] = useState([]); // Combined heatmap points
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [quote, setQuote] = useState('');
//     const quoteIntervalRef = useRef(null);
//     const [towersBeingProcessed, setTowersBeingProcessed] = useState([]);
//     const mapRef = useRef(); // Reference to the map instance
//     const [isPanelOpen, setIsPanelOpen] = useState(true); // State for panel visibility

//     const loadingQuotes = [
//         "Loading your results...",
//         "Calculating feasibility...",
//         "Analyzing data...",
//         "Please wait a moment...",
//         "I can see my house from up here...",
//         "Processing your request...",
//         "Loading your future network... one pixel at a time",
//         "There's no place like 127.0.0.1",
//     ];

//     // Function to get a random loading quote
//     const getRandomQuote = () => {
//         const randomIndex = Math.floor(Math.random() * loadingQuotes.length);
//         return loadingQuotes[randomIndex];
//     };

//     // Start rotating loading quotes
//     const startQuoteRotation = () => {
//         setQuote(getRandomQuote());
//         quoteIntervalRef.current = setInterval(() => {
//             setQuote(getRandomQuote());
//         }, 5000);
//     };

//     // Stop rotating loading quotes
//     const stopQuoteRotation = () => {
//         clearInterval(quoteIntervalRef.current);
//     };

//     // Fetch towers from backend API
//     const fetchTowers = async () => {
//         setLoading(true);
//         startQuoteRotation();
//         try {
//             const response = await axios.get('/api/get-base-points');
//             setTowers(response.data);
//             const initialSelection = {};
//             response.data.forEach(tower => {
//                 initialSelection[tower.id] = selectAll;
//             });
//             setSelectedBaseStations(initialSelection);
//             console.log('Towers fetched successfully:', response.data);

//             // After fetching towers, fetch coverage data for all towers
//             const allTowerIds = response.data.map(tower => tower.id);
//             await fetchCoverageData(allTowerIds);
//         } catch (error) {
//             console.error('Error fetching towers:', error);
//             setError('Failed to load towers.');
//         } finally {
//             setLoading(false);
//             stopQuoteRotation();
//         }
//     };

//     // Fetch coverage data from backend API for specific tower IDs
//     const fetchCoverageData = async (towerIds) => {
//         try {
//             const response = await axios.post('/api/check-coverage', {
//                 tower_ids: towerIds,
//             });
//             const fetchedCoverageData = response.data.coverageData;
//             console.log('Fetched coverage data:', fetchedCoverageData);

//             // Check if fetchedCoverageData is an object
//             if (fetchedCoverageData && typeof fetchedCoverageData === 'object' && !Array.isArray(fetchedCoverageData)) {
//                 // Convert the object to an array using Object.entries
//                 const coverageEntries = Object.entries(fetchedCoverageData);
//                 const updatedCoverageData = { ...coverageData };
//                 coverageEntries.forEach(([towerId, feature]) => {
//                     if (towerId && feature) {
//                         // Directly map towerId to feature without checking feature.properties.tower_id
//                         updatedCoverageData[towerId] = feature;
//                     }
//                 });
//                 setCoverageData(updatedCoverageData);
//                 console.log('Updated coverage data:', updatedCoverageData);
//             } else if (Array.isArray(fetchedCoverageData)) {
//                 // If coverageData is returned as an array, handle accordingly
//                 const updatedCoverageData = { ...coverageData };
//                 fetchedCoverageData.forEach(feature => {
//                     if (feature.properties && feature.properties.tower_id) {
//                         updatedCoverageData[feature.properties.tower_id] = feature;
//                     } else {
//                         console.warn('Coverage feature missing tower_id:', feature);
//                     }
//                 });
//                 setCoverageData(updatedCoverageData);
//                 console.log('Added coverage data array:', fetchedCoverageData);
//             } else {
//                 console.error('Unexpected coverage data format:', fetchedCoverageData);
//                 setError('Unexpected coverage data format received.');
//             }
//         } catch (error) {
//             console.error('Error fetching coverage data:', error);
//             setError('Failed to fetch coverage data.');
//         }
//     };

//     // Generate coverage by sending selected tower IDs to backend
//     const generateCoverage = async () => {
//         const selectedTowerIds = Object.keys(selectedBaseStations).filter(id => selectedBaseStations[id]);
//         if (selectedTowerIds.length === 0) {
//             setError('Please select at least one tower.');
//             console.error('No towers selected for generating coverage.');
//             return;
//         }

//         // Identify towers that lack coverage data
//         const towersNeedingCoverage = selectedTowerIds.filter(id => !coverageData[id]);

//         if (towersNeedingCoverage.length === 0) {
//             setError('All selected towers already have coverage data.');
//             console.log('All selected towers already have coverage data.');
//             return;
//         }

//         setLoading(true);
//         setError(null);
//         startQuoteRotation();

//         try {
//             const response = await axios.post('/api/generate-coverage', {
//                 tower_ids: towersNeedingCoverage,
//             });

//             const { existingCoverageData, towersBeingProcessed: processingTowers } = response.data;

//             // Add existing coverage data to state
//             if (existingCoverageData && typeof existingCoverageData === 'object') {
//                 const coverageEntries = Object.entries(existingCoverageData);
//                 const updatedCoverageData = { ...coverageData };
//                 coverageEntries.forEach(([towerId, feature]) => {
//                     if (towerId && feature) {
//                         updatedCoverageData[towerId] = feature;
//                     }
//                 });
//                 setCoverageData(updatedCoverageData);
//                 console.log('Added existing coverage data:', existingCoverageData);
//             }

//             // Handle towers being processed
//             if (processingTowers && Array.isArray(processingTowers) && processingTowers.length > 0) {
//                 console.log('Coverage generation started for towers:', processingTowers);
//                 setTowersBeingProcessed(processingTowers);
//             }

//             // Optionally, immediately fetch coverage data for towers that already have it
//             const towersCompleted = selectedTowerIds.filter(id => coverageData[id]);
//             if (towersCompleted.length > 0) {
//                 await fetchCoverageData(towersCompleted);
//             }
//         } catch (err) {
//             console.error('Error generating coverage:', err);
//             if (err.response) {
//                 console.error('Response error details:', err.response);
//                 if (err.response.data && err.response.data.error) {
//                     setError(err.response.data.error);
//                 } else {
//                     setError('An unexpected error occurred while generating coverage.');
//                 }
//             } else {
//                 console.error('Unexpected error, likely network issue:', err);
//                 setError('A network error occurred. Please check your connection.');
//             }
//         } finally {
//             setLoading(false);
//             stopQuoteRotation();
//         }
//     };

//     // Handle the "Build Coverage" button click with selection limit
//     const handleBuildCoverage = () => {
//         if (selectedTowerCount > 5) {
//             setError("Please only select 5 stations at a time for coverage generation.");
//             return;
//         }
//         console.log('Building coverage for selected base stations');
//         generateCoverage();
//     };

//     // Effect to fetch towers on component mount
//     useEffect(() => {
//         fetchTowers();
//     }, []);

//     // Effect to handle coverage processing (polling)
//     useEffect(() => {
//         let intervalId;

//         if (towersBeingProcessed.length > 0) {
//             intervalId = setInterval(async () => {
//                 try {
//                     // Check coverage status
//                     const statusResponse = await axios.post('/api/coverage-status', {
//                         tower_ids: towersBeingProcessed,
//                     });

//                     const { statusData } = statusResponse.data;
//                     const completedTowers = [];
//                     const failedTowers = [];

//                     for (const [tower_id, status] of Object.entries(statusData)) {
//                         if (status === 'completed') {
//                             completedTowers.push(tower_id);
//                         } else if (status === 'failed') {
//                             failedTowers.push(tower_id);
//                         }
//                     }

//                     // Fetch coverage data for completed towers
//                     if (completedTowers.length > 0) {
//                         await fetchCoverageData(completedTowers);

//                         // Remove completed towers from processing list
//                         setTowersBeingProcessed((prev) =>
//                             prev.filter((id) => !completedTowers.includes(id))
//                         );
//                     }

//                     // Handle failed towers
//                     if (failedTowers.length > 0) {
//                         // Remove failed towers from processing list
//                         setTowersBeingProcessed((prev) =>
//                             prev.filter((id) => !failedTowers.includes(id))
//                         );

//                         // Display an error message
//                         setError(`Coverage generation failed for towers: ${failedTowers.join(', ')}`);
//                     }

//                     // Stop polling if all towers are processed
//                     if (towersBeingProcessed.length === 0) {
//                         clearInterval(intervalId);
//                     }
//                 } catch (error) {
//                     console.error('Error checking coverage status:', error);
//                 }
//             }, 5000); // Poll every 5 seconds
//         }

//         return () => {
//             if (intervalId) {
//                 clearInterval(intervalId);
//             }
//         };
//     }, [towersBeingProcessed]);

//     // Effect to process coverageData into heatmapData
//     useEffect(() => {
//         const selectedTowersWithCoverage = Object.keys(selectedBaseStations).filter(
//             (id) => selectedBaseStations[id] && coverageData[id]
//         );

//         const combinedHeatPoints = selectedTowersWithCoverage.reduce((acc, towerId) => {
//             const feature = coverageData[towerId];
//             if (feature && feature.geometry && feature.geometry.coordinates) {
//                 feature.geometry.coordinates[0].forEach((coord) => {
//                     const [lng, lat] = coord;
//                     acc.push([lat, lng, 0.5]); // [lat, lng, intensity]
//                 });
//             }
//             return acc;
//         }, []);

//         console.log('Combined Heatmap Data:', combinedHeatPoints);
//         setHeatmapData(combinedHeatPoints);
//     }, [coverageData, selectedBaseStations]);

//     // Function to handle Select All checkbox change
//     const handleSelectAllChange = () => {
//         const newSelectAll = !selectAll;
//         setSelectAll(newSelectAll);
//         const newSelection = {};
//         towers.forEach(tower => {
//             newSelection[tower.id] = newSelectAll;
//         });
//         setSelectedBaseStations(newSelection);
//         console.log('Select All changed to:', newSelectAll);
//     };

//     // Function to handle individual base station checkbox change
//     const handleBaseStationChange = (stationId) => {
//         setSelectedBaseStations(prevState => {
//             const newState = {
//                 ...prevState,
//                 [stationId]: !prevState[stationId],
//             };
//             console.log(`Base station ${stationId} selection changed to:`, newState[stationId]);
//             return newState;
//         });
//     };

//     // Identify towers that need coverage generation
//     const towersNeedingCoverage = Object.keys(selectedBaseStations).filter(
//         (id) => selectedBaseStations[id] && !coverageData[id]
//     );

//     // Calculate the number of selected towers
//     const selectedTowerCount = Object.values(selectedBaseStations).filter(val => val).length;

//     // Function to toggle the visibility of the select towers panel
//     const togglePanel = () => {
//         setIsPanelOpen(!isPanelOpen);
//     };

//     return (
//         <div style={styles.pageContainer}>
//             <Top /> {/* Integrate the Top menu at the top */}
//             {/* Spacer to prevent overlap with Top menu */}
//             <div style={styles.topMenuSpacer}></div>
//             <div id="map-container" style={styles.mapWrapper}>
//                 {/* Gear Icon to Toggle the Select Towers Panel */}
//                 <div style={styles.gearIcon} onClick={togglePanel} title="Toggle Coverage Selection">
//                     <FaCog size={20} />
//                 </div>

//                 {/* Select Towers for Coverage Area Panel */}
//                 <div
//                     style={{
//                         ...styles.searchContainer,
//                         width: '360px', // Increased width by 20% from 300px to 360px
//                         transform: isPanelOpen ? 'translateX(0)' : 'translateX(-100%)',
//                     }}
//                 >
//                     <div>
//                         <label>
//                             <input
//                                 type="checkbox"
//                                 checked={selectAll}
//                                 onChange={handleSelectAllChange}
//                             />
//                             Select All
//                         </label>
//                         <div style={{ maxHeight: '250px', overflowY: 'auto', marginTop: '10px' }}>
//                             {towers.map((tower) => (
//                                 <div key={tower.id}>
//                                     <input
//                                         type="checkbox"
//                                         checked={selectedBaseStations[tower.id] || false}
//                                         onChange={() => handleBaseStationChange(tower.id)}
//                                     />
//                                     <span>{tower.name}</span>
//                                     {/* Indicate if coverage is missing */}
//                                     {selectedBaseStations[tower.id] && !coverageData[tower.id] && (
//                                         <span style={{ color: 'red', marginLeft: '5px' }}>
//                                             (Coverage Missing)
//                                         </span>
//                                     )}
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                     <button 
//                         style={styles.buildButton} 
//                         onClick={handleBuildCoverage} 
//                         disabled={towersNeedingCoverage.length === 0 || loading}
//                     >
//                         {loading ? 'Generating...' : 'Build Coverage'}
//                     </button>
//                     {/* Inform the user if no towers are selected */}
//                     {selectedTowerCount === 0 && (
//                         <div style={{ marginTop: '10px', color: 'gray', textAlign: 'center' }}>
//                             No Towers Selected.
//                         </div>
//                     )}
//                     {/* Inform the user if no towers need coverage */}
//                     {selectedTowerCount > 0 && towersNeedingCoverage.length === 0 && (
//                         <div style={{ marginTop: '10px', color: 'green', textAlign: 'center' }}>
//                             All selected towers have coverage data.
//                         </div>
//                     )}
//                     {/* Display error messages */}
//                     {error && <div style={styles.errorMessage}>{error}</div>}
//                 </div>

//                 <MapContainer
//                     center={[latLng.lat, latLng.lng]}
//                     zoom={11}
//                     scrollWheelZoom={true}
//                     style={styles.mapContainer}
//                     attributionControl={false}
//                     whenCreated={(mapInstance) => { mapRef.current = mapInstance; }}
//                 >
//                     <TileLayer 
//                         url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" 
//                         attribution='&copy; <a href="https://carto.com/">CARTO</a>'
//                     />
//                     <CustomAttribution />
                    
//                     {/* Render the initial office marker */}
//                     <Marker 
//                         position={[startingOfficeCoords.lat, startingOfficeCoords.lng]} 
//                         icon={officeMarkerIcon}
//                     >
//                         <Popup>
//                             <div>
//                                 <h3>Office</h3>
//                                 <p>Coordinates: ({startingOfficeCoords.lat}, {startingOfficeCoords.lng})</p>
//                             </div>
//                         </Popup>
//                     </Marker>

//                     {/* Render all selected base station markers with coverage */}
//                     {towers.map((tower) => (
//                         selectedBaseStations[tower.id] && coverageData[tower.id] && (
//                             <Marker
//                                 key={tower.id}
//                                 position={[tower.latitude, tower.longitude]}
//                                 icon={towerIcon}
//                             >
//                                 <Popup>
//                                     <div>
//                                         <h3>{tower.name}</h3>
//                                         <p>Coordinates: ({tower.latitude}, {tower.longitude})</p>
//                                         <p>Coverage Status: Covered</p>
//                                     </div>
//                                 </Popup>
//                             </Marker>
//                         )
//                     ))}

//                     {/* Update map center when latLng state changes */}
//                     <UpdateMapCenter latLng={latLng} />

//                     {/* Render the heatmap layer */}
//                     <HeatmapLayer heatmapData={heatmapData} />
//                 </MapContainer>

//                 {/* Loader Overlay */}
//                 {loading &&
//                     <div className="loader-overlay" style={styles.loaderContainer}>
//                         <div className="loader"></div>
//                         <p style={styles.loadingQuote}>{quote}</p>
//                     </div>
//                 }
//             </div>
//         </div>
//     );

// };

// export default CoverageMap;
import React, { useState, useEffect, useRef } from 'react';
import { 
    MapContainer, 
    TileLayer, 
    Marker, 
    Popup, 
    useMap 
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.heat';
import axios from 'axios';
import './CoverageMap.css';
import './Loader.css';
import { FaCog } from 'react-icons/fa';
import Top from './Top'; // Importing the Top menu component

// Constants for map setup
const startingOfficeCoords = { lat: -20.264961457551927, lng: 57.59215085036571 };
const startingOfficeLogo = '/markersm.png'; // Ensure the image is in the public folder

// Define the custom icon for the office marker
const officeMarkerIcon = new L.Icon({
    iconUrl: startingOfficeLogo,
    iconSize: [25, 38],
    iconAnchor: [12, 38],
    popupAnchor: [1, -34],
});

// Define the custom icon for tower markers
const towerIconPath = '/markersm.png'; // Ensure the image is in the public folder
const towerIcon = new L.Icon({
    iconUrl: towerIconPath,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

// Styles for various components
const styles = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    topMenuSpacer: {
        height: '5px', // Adjust to match the Top menu height
    },
    mapWrapper: {
        flex: 1,
        position: 'relative',
    },
    mapContainer: {
        height: '100%',
        width: '100%',
    },
    searchContainer: {
        position: 'absolute',
        top: '130px', // Adjusted to accommodate the Top menu
        left: '0px', // Shifted right to make room for the gear icon
        zIndex: 1000,
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
        maxHeight: '90vh',
        overflowY: 'auto',
        width: '360px', // Increased width by 20% from 300px to 360px
        transition: 'transform 0.8s ease-in-out',
        transform: 'translateX(0)', // Default to visible
    },
    loaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 2000,
    },
    loadingQuote: {
        marginTop: '20px',
        fontSize: '1em',
        textAlign: 'center',
        color: '#333',
    },
    popupContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    popupText: {
        marginBottom: '10px',
    },
    searchButton: {
        marginTop: '10px',
        padding: '5px 10px',
        backgroundColor: '#61acb4',
        color: 'white',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    },
    buildButton: {
        marginTop: '10px',
        padding: '10px 20px',
        backgroundColor: '#61acb4',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    errorMessage: {
        marginTop: '10px',
        color: 'red',
        textAlign: 'center',
    },
    gearIcon: {
        position: 'absolute',
        top: '80px', // Positioned below the Top menu
        left: '10px',
        zIndex: 1100,
        backgroundColor: '#fff',
        padding: '8px',
        borderRadius: '50%',
        boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
        cursor: 'pointer',
    },
    searchPanelClosed: {
        transform: 'translateX(-100%)',
    },
    searchPanelOpen: {
        transform: 'translateX(0)',
    },
    
};

// Helper component to update the map center
function UpdateMapCenter({ latLng }) {
    const map = useMap();
    useEffect(() => {
        map.setView([latLng.lat, latLng.lng], 11);
    }, [latLng, map]);
    return null;
}

// Helper component to add custom attribution (e.g., logo)
function CustomAttribution() {
    const map = useMap();

    useEffect(() => {
        const logoControl = L.control({ position: 'bottomright' });
        logoControl.onAdd = function () {
            const div = L.DomUtil.create('div', 'custom-attribution');
            div.innerHTML = `<img src="/smartmaplogotext.png" alt="Logo" style="height: 40px;"/>`; // Ensure the image is in the public folder
            return div;
        };
        logoControl.addTo(map);
        return () => {
            map.removeControl(logoControl);
        };
    }, [map]);

    return null;
}

// Helper component to render the heatmap layer
// const HeatmapLayer = ({ heatmapData }) => {
//     const map = useMap();

//     useEffect(() => {
//         if (!heatmapData || heatmapData.length === 0) return;

//         const heatLayer = L.heatLayer(heatmapData, {
//             radius: 25,
//             blur: 15,
//             maxZoom: 17,
//             maxOpacity: 0.5, // 70% opacity for 30% transparency
//             minOpacity: 0.2, // Minimum opacity for the heatmap layer
//             gradient: { 
//                 0.2: 'blue', 
//                 0.4: 'lime', 
//                 0.6: 'yellow', 
//                 1: 'red' 
//             },            
//         }).addTo(map);

//         return () => {
//             map.removeLayer(heatLayer);
//         };
//     }, [heatmapData, map]);

//     return null;
// };

const HeatmapLayer = ({ heatmapData }) => {
    const map = useMap();

    useEffect(() => {
        if (!heatmapData || heatmapData.length === 0) return;

        const heatLayer = L.heatLayer(heatmapData, {
            radius: 25,
            blur: 15,
            maxZoom: 17,
            gradient: { 0.2: 'red', 0.4: 'yellow', 0.7: 'lime', 1: 'blue' },
        }).addTo(map);

        // Set the opacity of the heatmap layer dynamically
        const heatmapCanvas = heatLayer._canvas;
        if (heatmapCanvas) {
            heatmapCanvas.style.opacity = '0.5'; // 50% transparency
        }

        return () => {
            map.removeLayer(heatLayer);
        };
    }, [heatmapData, map]);

    return null;
};


// Main CoverageMap component
const CoverageMap = () => {
    const [latLng, setLatLng] = useState(startingOfficeCoords);
    const [towers, setTowers] = useState([]);
    const [selectedBaseStations, setSelectedBaseStations] = useState({});
    const [selectAll, setSelectAll] = useState(true);
    const [coverageData, setCoverageData] = useState({}); // { towerId: coverageGeoJSON }
    const [heatmapData, setHeatmapData] = useState([]); // Combined heatmap points
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [quote, setQuote] = useState('');
    const quoteIntervalRef = useRef(null);
    const [towersBeingProcessed, setTowersBeingProcessed] = useState([]);
    const mapRef = useRef(); // Reference to the map instance
    const [isPanelOpen, setIsPanelOpen] = useState(true); // State for panel visibility

    const loadingQuotes = [
        "Loading your results...",
        "Calculating feasibility...",
        "Analyzing data...",
        "Please wait a moment...",
        "I can see my house from up here...",
        "Processing your request...",
        "Loading your future network... one pixel at a time",
        "There's no place like 127.0.0.1",
    ];

    // Function to get a random loading quote
    const getRandomQuote = () => {
        const randomIndex = Math.floor(Math.random() * loadingQuotes.length);
        return loadingQuotes[randomIndex];
    };

    // Start rotating loading quotes
    const startQuoteRotation = () => {
        setQuote(getRandomQuote());
        quoteIntervalRef.current = setInterval(() => {
            setQuote(getRandomQuote());
        }, 5000);
    };

    // Stop rotating loading quotes
    const stopQuoteRotation = () => {
        clearInterval(quoteIntervalRef.current);
    };

    // Fetch towers from backend API
    const fetchTowers = async () => {
        setLoading(true);
        startQuoteRotation();
        try {
            const response = await axios.get('/api/get-base-points');
            setTowers(response.data);
            const initialSelection = {};
            response.data.forEach(tower => {
                initialSelection[tower.id] = selectAll;
            });
            setSelectedBaseStations(initialSelection);
            console.log('Towers fetched successfully:', response.data);

            // After fetching towers, fetch coverage data for all towers
            const allTowerIds = response.data.map(tower => tower.id);
            await fetchCoverageData(allTowerIds);
        } catch (error) {
            console.error('Error fetching towers:', error);
            setError('Failed to load towers.');
        } finally {
            setLoading(false);
            stopQuoteRotation();
        }
    };

    // Fetch coverage data from backend API for specific tower IDs
    const fetchCoverageData = async (towerIds) => {
        try {
            const response = await axios.post('/api/check-coverage', {
                tower_ids: towerIds,
            });
            const fetchedCoverageData = response.data.coverageData;
            console.log('Fetched coverage data:', fetchedCoverageData);

            // Check if fetchedCoverageData is an object
            if (fetchedCoverageData && typeof fetchedCoverageData === 'object' && !Array.isArray(fetchedCoverageData)) {
                // Convert the object to an array using Object.entries
                const coverageEntries = Object.entries(fetchedCoverageData);
                const updatedCoverageData = { ...coverageData };
                coverageEntries.forEach(([towerId, feature]) => {
                    if (towerId && feature) {
                        // Directly map towerId to feature without checking feature.properties.tower_id
                        updatedCoverageData[towerId] = feature;
                    }
                });
                setCoverageData(updatedCoverageData);
                console.log('Updated coverage data:', updatedCoverageData);
            } else if (Array.isArray(fetchedCoverageData)) {
                // If coverageData is returned as an array, handle accordingly
                const updatedCoverageData = { ...coverageData };
                fetchedCoverageData.forEach(feature => {
                    if (feature.properties && feature.properties.tower_id) {
                        updatedCoverageData[feature.properties.tower_id] = feature;
                    } else {
                        console.warn('Coverage feature missing tower_id:', feature);
                    }
                });
                setCoverageData(updatedCoverageData);
                console.log('Added coverage data array:', fetchedCoverageData);
            } else {
                console.error('Unexpected coverage data format:', fetchedCoverageData);
                setError('Unexpected coverage data format received.');
            }
        } catch (error) {
            console.error('Error fetching coverage data:', error);
            setError('Failed to fetch coverage data.');
        }
    };

    // Generate coverage by sending selected tower IDs to backend
    const generateCoverage = async () => {
        const selectedTowerIds = Object.keys(selectedBaseStations).filter(id => selectedBaseStations[id]);
        if (selectedTowerIds.length === 0) {
            setError('Please select at least one tower.');
            console.error('No towers selected for generating coverage.');
            return;
        }

        // Identify towers that lack coverage data
        const towersNeedingCoverage = selectedTowerIds.filter(id => !coverageData[id]);

        if (towersNeedingCoverage.length === 0) {
            setError('All selected towers already have coverage data.');
            console.log('All selected towers already have coverage data.');
            return;
        }

        setLoading(true);
        setError(null);
        startQuoteRotation();

        try {
            const response = await axios.post('/api/generate-coverage', {
                tower_ids: towersNeedingCoverage,
            });

            const { existingCoverageData, towersBeingProcessed: processingTowers } = response.data;

            // Add existing coverage data to state
            if (existingCoverageData && typeof existingCoverageData === 'object') {
                const coverageEntries = Object.entries(existingCoverageData);
                const updatedCoverageData = { ...coverageData };
                coverageEntries.forEach(([towerId, feature]) => {
                    if (towerId && feature) {
                        updatedCoverageData[towerId] = feature;
                    }
                });
                setCoverageData(updatedCoverageData);
                console.log('Added existing coverage data:', existingCoverageData);
            }

            // Handle towers being processed
            if (processingTowers && Array.isArray(processingTowers) && processingTowers.length > 0) {
                console.log('Coverage generation started for towers:', processingTowers);
                setTowersBeingProcessed(processingTowers);
            }

            // Optionally, immediately fetch coverage data for towers that already have it
            const towersCompleted = selectedTowerIds.filter(id => coverageData[id]);
            if (towersCompleted.length > 0) {
                await fetchCoverageData(towersCompleted);
            }
        } catch (err) {
            console.error('Error generating coverage:', err);
            if (err.response) {
                console.error('Response error details:', err.response);
                if (err.response.data && err.response.data.error) {
                    setError(err.response.data.error);
                } else {
                    setError('An unexpected error occurred while generating coverage.');
                }
            } else {
                console.error('Unexpected error, likely network issue:', err);
                setError('A network error occurred. Please check your connection.');
            }
        } finally {
            setLoading(false);
            stopQuoteRotation();
        }
    };

    // Handle the "Build Coverage" button click with selection limit
    const handleBuildCoverage = () => {
        if (selectedTowerCount > 5) {
            setError("Please only select 5 stations at a time for coverage generation.");
            return;
        }
        console.log('Building coverage for selected base stations');
        generateCoverage();
    };

    // Effect to fetch towers on component mount
    useEffect(() => {
        fetchTowers();
    }, []);

    // Effect to handle coverage processing (polling)
    useEffect(() => {
        let intervalId;

        if (towersBeingProcessed.length > 0) {
            intervalId = setInterval(async () => {
                try {
                    // Check coverage status
                    const statusResponse = await axios.post('/api/coverage-status', {
                        tower_ids: towersBeingProcessed,
                    });

                    const { statusData } = statusResponse.data;
                    const completedTowers = [];
                    const failedTowers = [];

                    for (const [tower_id, status] of Object.entries(statusData)) {
                        if (status === 'completed') {
                            completedTowers.push(tower_id);
                        } else if (status === 'failed') {
                            failedTowers.push(tower_id);
                        }
                    }

                    // Fetch coverage data for completed towers
                    if (completedTowers.length > 0) {
                        await fetchCoverageData(completedTowers);

                        // Remove completed towers from processing list
                        setTowersBeingProcessed((prev) =>
                            prev.filter((id) => !completedTowers.includes(id))
                        );
                    }

                    // Handle failed towers
                    if (failedTowers.length > 0) {
                        // Remove failed towers from processing list
                        setTowersBeingProcessed((prev) =>
                            prev.filter((id) => !failedTowers.includes(id))
                        );

                        // Display an error message
                        setError(`Coverage generation failed for towers: ${failedTowers.join(', ')}`);
                    }

                    // Stop polling if all towers are processed
                    if (towersBeingProcessed.length === 0) {
                        clearInterval(intervalId);
                    }
                } catch (error) {
                    console.error('Error checking coverage status:', error);
                }
            }, 5000); // Poll every 5 seconds
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [towersBeingProcessed]);

    // Effect to process coverageData into heatmapData
    useEffect(() => {
        const selectedTowersWithCoverage = Object.keys(selectedBaseStations).filter(
            (id) => selectedBaseStations[id] && coverageData[id]
        );

        const combinedHeatPoints = selectedTowersWithCoverage.reduce((acc, towerId) => {
            const feature = coverageData[towerId];
            if (feature && feature.geometry && feature.geometry.coordinates) {
                feature.geometry.coordinates[0].forEach((coord) => {
                    const [lng, lat] = coord;
                    acc.push([lat, lng, 0.3]); // [lat, lng, intensity]
                });
            }
            return acc;
        }, []);

        console.log('Combined Heatmap Data:', combinedHeatPoints);
        setHeatmapData(combinedHeatPoints);
    }, [coverageData, selectedBaseStations]);

    // Function to handle Select All checkbox change
    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const newSelection = {};
        towers.forEach(tower => {
            newSelection[tower.id] = newSelectAll;
        });
        setSelectedBaseStations(newSelection);
        console.log('Select All changed to:', newSelectAll);
    };

    // Function to handle individual base station checkbox change
    const handleBaseStationChange = (stationId) => {
        setSelectedBaseStations(prevState => {
            const newState = {
                ...prevState,
                [stationId]: !prevState[stationId],
            };
            console.log(`Base station ${stationId} selection changed to:`, newState[stationId]);
            return newState;
        });
    };

    // Identify towers that need coverage generation
    const towersNeedingCoverage = Object.keys(selectedBaseStations).filter(
        (id) => selectedBaseStations[id] && !coverageData[id]
    );

    // Calculate the number of selected towers
    const selectedTowerCount = Object.values(selectedBaseStations).filter(val => val).length;

    // Function to toggle the visibility of the select towers panel
    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    return (
        <div style={styles.pageContainer}>
            <Top /> {/* Integrate the Top menu at the top */}
            {/* Spacer to prevent overlap with Top menu */}
            <div style={styles.topMenuSpacer}></div>
            <div id="map-container" style={styles.mapWrapper}>
                {/* Gear Icon to Toggle the Select Towers Panel */}
                <div style={styles.gearIcon} onClick={togglePanel} title="Toggle Coverage Selection">
                    <FaCog size={20} />
                </div>

                {/* Select Towers for Coverage Area Panel */}
                <div
                    style={{
                        ...styles.searchContainer,
                        width: '360px', // Increased width by 20% from 300px to 360px
                        transform: isPanelOpen ? 'translateX(0)' : 'translateX(-100%)',
                    }}
                >
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                            />
                            Select All
                        </label>
                        <div style={{ maxHeight: '250px', overflowY: 'auto', marginTop: '10px' }}>
                            {towers.map((tower) => (
                                <div key={tower.id}>
                                    <input
                                        type="checkbox"
                                        checked={selectedBaseStations[tower.id] || false}
                                        onChange={() => handleBaseStationChange(tower.id)}
                                    />
                                    <span>{tower.name}</span>
                                    {/* Indicate if coverage is missing */}
                                    {selectedBaseStations[tower.id] && !coverageData[tower.id] && (
                                        <span style={{ color: 'red', marginLeft: '5px' }}>
                                            (Coverage Missing)
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <button 
                        style={styles.buildButton} 
                        onClick={handleBuildCoverage} 
                        disabled={towersNeedingCoverage.length === 0 || loading}
                    >
                        {loading ? 'Generating...' : 'Build Coverage'}
                    </button>
                    {/* Inform the user if no towers are selected */}
                    {selectedTowerCount === 0 && (
                        <div style={{ marginTop: '10px', color: 'gray', textAlign: 'center' }}>
                            No Towers Selected.
                        </div>
                    )}
                    {/* Inform the user if no towers need coverage */}
                    {selectedTowerCount > 0 && towersNeedingCoverage.length === 0 && (
                        <div style={{ marginTop: '10px', color: 'green', textAlign: 'center' }}>
                            All selected towers have coverage data.
                        </div>
                    )}
                    {/* Display error messages */}
                    {error && <div style={styles.errorMessage}>{error}</div>}
                </div>

                <MapContainer
                    center={[latLng.lat, latLng.lng]}
                    zoom={11}
                    scrollWheelZoom={true}
                    style={styles.mapContainer}
                    attributionControl={false}
                    whenCreated={(mapInstance) => { mapRef.current = mapInstance; }}
                >
                    <TileLayer 
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" 
                        attribution='&copy; <a href="https://carto.com/">CARTO</a>'
                    />
                    <CustomAttribution />
                    
                    {/* Render the initial office marker */}
                    <Marker 
                        position={[startingOfficeCoords.lat, startingOfficeCoords.lng]} 
                        icon={officeMarkerIcon}
                    >
                        <Popup>
                            <div>
                                <h3>Office</h3>
                                <p>Coordinates: ({startingOfficeCoords.lat}, {startingOfficeCoords.lng})</p>
                            </div>
                        </Popup>
                    </Marker>

                    {/* Render all selected base station markers with coverage */}
                    {towers.map((tower) => (
                        selectedBaseStations[tower.id] && coverageData[tower.id] && (
                            <Marker
                                key={tower.id}
                                position={[tower.latitude, tower.longitude]}
                                icon={towerIcon}
                            >
                                <Popup>
                                    <div>
                                        <h3>{tower.name}</h3>
                                        <p>Coordinates: ({tower.latitude}, {tower.longitude})</p>
                                        <p>Coverage Status: Covered</p>
                                    </div>
                                </Popup>
                            </Marker>
                        )
                    ))}

                    {/* Update map center when latLng state changes */}
                    <UpdateMapCenter latLng={latLng} />

                    {/* Render the heatmap layer */}
                    <HeatmapLayer heatmapData={heatmapData} />
                </MapContainer>

                {/* Loader Overlay */}
                {loading &&
                    <div className="loader-overlay" style={styles.loaderContainer}>
                        <div className="loader"></div>
                        <p style={styles.loadingQuote}>{quote}</p>
                    </div>
                }
            </div>
        </div>
    );

};

export default CoverageMap;
