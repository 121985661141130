import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaNetworkWired, FaWifi, FaClipboardList, FaCog, FaUser, FaBuilding } from 'react-icons/fa';

function Top() {
    const [showMenu, setShowMenu] = useState(false); // State to toggle the Settings menu

    // Toggle the display of the Settings menu
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div style={styles.topContainer}>
            <div style={styles.centerGroup}>
                {/* Left side icon: Wireless Feasibility */}
                <Link to="/wireless-feasibility" style={styles.iconLink}>
                    <FaWifi size={40} style={styles.icon} />
                    <div style={styles.iconText}>
                        <span>My Network</span>
                    </div>
                </Link>

                {/* Left side icon: Fibre Feasibility */}
                <Link to="/fibre-feasibility" style={styles.iconLink}>
                    <FaNetworkWired size={40} style={styles.icon} />
                    <div style={styles.iconText}>
                        <span>Other Networks</span>
                        
                    </div>
                </Link>

                {/* Center: Logo */}
                <div style={styles.logoContainer}>
                    <img src="/smartmaplogotext.png" alt="SmartMap Logo" style={styles.logo} />
                </div>

                {/* Removed Mobile Feasibility button */}

                {/* Replaced with Settings icon */}
                <div style={styles.iconLink} onClick={toggleMenu}>
                    <FaCog size={40} style={styles.icon} />
                    <div style={styles.iconText}>
                        <span>Settings</span>
                    </div>
                    {showMenu && (
                        <div style={styles.menu}>
                            <Link to="/manage-towers" style={styles.menuItem}>
                                <FaBuilding size={20} style={styles.menuIcon} /> Base Stations
                            </Link>
                            <Link to="/manage-statistics" style={styles.menuItem}>
                                <FaNetworkWired size={20} style={styles.menuIcon} />  Statistics
                            </Link>
                            <Link to="/manage-users" style={styles.menuItem}>
                                <FaUser size={20} style={styles.menuIcon} />  Users
                            </Link>
                            <Link to="/coverage" style={styles.menuItem}>
                                <FaUser size={20} style={styles.menuIcon} />  Coverage Generator
                            </Link>
                        </div>
                    )}
                </div>

                {/* Right side icon: Past Results */}
                <Link to="/past-results" style={styles.iconLink}>
                    <FaClipboardList size={40} style={styles.icon} />
                    <div style={styles.iconText}>
                        
                        <span>Reports</span>
                    </div>
                </Link>
            </div>
        </div>
    );
}

const styles = {
    topContainer: {
        display: 'flex',
        justifyContent: 'center',  // Center content horizontally
        alignItems: 'center',
        height: '20vh',
        backgroundColor: '#ffffff', // White background
        padding: '0 50px',
    },
    centerGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  // Center icons and logo
    },
    logoContainer: {
        margin: '0 50px',  // Adjust margin to space around the logo
    },
    logo: {
        maxHeight: '50px',
        height: 'auto',
    },
    iconLink: {
        textDecoration: 'none',
        color: '#61acb4',
        textAlign: 'center',
        margin: '0 20px',  // Increase spacing between icons
        position: 'relative',  // For positioning the settings menu
    },
    icon: {
        marginBottom: '10px',
    },
    iconText: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        lineHeight: '1.2',
        color: 'slategray',
    },
    menu: {
        position: 'absolute',
        top: '50px',
        left: '0',
        backgroundColor: 'white',
        border: '1px solid slategray',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        zIndex: 1000,
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'slategray',
        padding: '8px',
        fontSize: '16px',
    },
    menuIcon: {
        marginRight: '8px',
        color: 'slategray',
    },
};

export default Top;
