// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Wireless from './components/Wireless';
import Fibre from './components/Fibre';
import Mobile from './components/Mobile';
import Results from './components/Results';
import Towers from './components/Towers';
import Users from './components/Users';
import Statistics from './components/Statistics';
import CoverageMap from './components/CoverageMap'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/wireless-feasibility" element={<Wireless />} />
                <Route path="/fibre-feasibility" element={<Fibre />} />
                <Route path="/mobile-feasibility" element={<Mobile />} />
                <Route path="/past-results" element={<Results />} />
                <Route path="/manage-towers" element={<Towers />} />
                <Route path="/manage-users" element={<Users />} />
                <Route path="/manage-statistics" element={<Statistics />} />
                <Route path="/coverage" element={<CoverageMap />} />
            </Routes>
        </Router>
    );
}

export default App;
